import {
  useCreateUser,
  useGetAccountById,
  useGetAccountContacts,
} from '@agyt/client/backoffice/data-access/api';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
  Button,
  DataTable,
  DetailsCard,
  LoadingButton,
  PageWrapper,
} from '@agyt/client/shared/ui/components';
import { IconPlusCircle } from '@agyt/client/shared/ui/icons';
import {
  ContactResponse,
  ContactWithExternalIdResponse,
} from '@agyt/shared/types';
import { Link, useParams } from '@tanstack/react-router';
import { ColumnDef, useReactTable } from '@tanstack/react-table';
import { getCoreRowModel } from '@tanstack/react-table';
import { useMemo, useState } from 'react';

const accountDetailOrderMap: {
  [key: string]: {
    order: number;
    label: string;
  };
} = {
  accountName: { order: 0, label: 'Name' },
  street: { order: 1, label: 'Address' },
  city: { order: 2, label: 'City' },
  country: { order: 3, label: 'Country' },
  status: { order: 4, label: 'Status' },
};

function UsersDetailsPage() {
  const { id } = useParams({ strict: false });
  const { data } = useGetAccountById(id);
  const { data: contacts } = useGetAccountContacts(id);
  const { mutate: createUser, isPending: isCreateUserPending } =
    useCreateUser();

  const [loadingId, setLoadingId] = useState<string | null>(null);

  const accountDetails = useMemo(() => data?.data, [data]);

  const account = useMemo(() => {
    if (!accountDetails) {
      return [];
    }

    return Object.keys(accountDetailOrderMap).map((key) => {
      const { label } = accountDetailOrderMap[key];

      if (key === 'status') {
        const value = (accountDetails as any)[key];
        return {
          key: label,
          value: value?.charAt(0).toUpperCase() + value?.slice(1),
        };
      }

      return {
        key: label,
        value: (accountDetails as any)[key],
      };
    });
  }, [accountDetails]);

  function handleCreateUser({
    email,
    firstName,
    lastName,
    contactId,
    locale,
    tz,
  }: {
    email: string;
    firstName: string;
    lastName: string;
    contactId: string;
    locale: string;
    tz: string;
  }) {
    try {
      createUser({
        email,
        name: `${firstName} ${lastName}`,
        contactId,
        accountId: id,
        locale,
        tz,
        country: accountDetails?.country || 'GB',
      });
    } catch (error) {
      console.error(error);
      alert('An error occurred while creating the user. Please try again.');
    }
  }

  const contactsColumns: ColumnDef<ContactWithExternalIdResponse>[] = [
    {
      header: 'Name',
      cell: ({ row }) => {
        return (
          <div>
            {row.original.firstName} {row.original.lastName}
          </div>
        );
      },
    },
    {
      header: 'Email',
      accessorKey: 'email',
    },
    {
      header: 'Details',
      cell: ({ row }) => {
        if (!row.original.externalId) {
          return (
            <LoadingButton
              className="flex w-[134px] gap-2"
              isLoading={isCreateUserPending && loadingId === row.original.id}
              disabled={isCreateUserPending && loadingId === row.original.id}
              onClick={() => {
                setLoadingId(row.original.id);
                handleCreateUser({
                  email: row.original.email,
                  firstName: row.original.firstName,
                  lastName: row.original.lastName,
                  contactId: row.original.id,
                  locale: row.original.locale,
                  tz: row.original.timezone,
                });
              }}
            >
              <IconPlusCircle />
              Create User
            </LoadingButton>
          );
        }

        return (
          <div>
            <span className="text-green-500">Connected to Auth0</span>
            <br />
            <span className="text-gray-500">ID: {row.original.externalId}</span>
          </div>
        );
      },
    },
  ];

  const defaultTable = useReactTable({
    data: contacts?.data || [],
    columns: contactsColumns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <PageWrapper>
      <Breadcrumb className="mb-10">
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbPage>
              <Link to="/accounts" className="cursor-pointer">
                Accounts
              </Link>
            </BreadcrumbPage>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage>{id}</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <header className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <h1 className="text-3xl font-medium">Account Details Page</h1>
        </div>
      </header>

      {!!account.length && (
        <section className="mb-8 w-1/2">
          <DetailsCard data={account} />
        </section>
      )}

      <section className="w-2/3">
        <h2 className="mb-4 text-lg font-medium">Contacts</h2>
        <DataTable<ContactWithExternalIdResponse>
          cellClassName="h-16 p-2"
          table={defaultTable}
        />
      </section>
    </PageWrapper>
  );
}

export default UsersDetailsPage;
