import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { useHttpClient } from '@agyt/client/backoffice/core/http';
import {
  SuccessApiResponse,
  AccountResponse,
  ContactResponse,
  CreateUserPayloadBody,
  ContactWithExternalIdResponse,
} from '@agyt/shared/types';

const ACCOUNTS_CACHE_KEY = 'accounts';
const ACCOUNTS_CONTACTS_CACHE_KEY = 'accounts-contacts';

export function useFindAccounts() {
  const { httpClient } = useHttpClient();
  return useQuery({
    queryKey: [ACCOUNTS_CACHE_KEY],
    async queryFn() {
      const res = await httpClient.get(`/accounts`);
      return res?.data as SuccessApiResponse<AccountResponse[]>;
    },
    placeholderData: keepPreviousData,
  });
}

export function useGetAccountById(id: string) {
  const { httpClient } = useHttpClient();
  return useQuery({
    queryKey: [ACCOUNTS_CACHE_KEY, id],
    async queryFn() {
      const res = await httpClient.get(`/accounts/${id}`);
      return res?.data as SuccessApiResponse<AccountResponse>;
    },
    enabled: !!id,
    placeholderData: keepPreviousData,
  });
}

export function useGetAccountContacts(id: string) {
  const { httpClient } = useHttpClient();
  return useQuery({
    queryKey: [ACCOUNTS_CONTACTS_CACHE_KEY, id],
    async queryFn() {
      const res = await httpClient.get(`/accounts/${id}/contacts`);
      return res?.data as SuccessApiResponse<ContactWithExternalIdResponse[]>;
    },
    enabled: !!id,
    placeholderData: keepPreviousData,
  });
}

export function useCreateUser() {
  const { httpClient } = useHttpClient();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (criteria: CreateUserPayloadBody) => {
      try {
        const res = await httpClient.post('/accounts/create-user', {
          email: criteria.email,
          name: criteria.name,
          contactId: criteria.contactId,
          accountId: criteria.accountId,
          locale: criteria.locale,
          tz: criteria.tz,
          country: criteria.country,
        });

        return res.data;
      } catch (err: any) {
        return Promise.reject(err);
      }
    },
    onSuccess() {
      return queryClient.invalidateQueries({
        queryKey: [ACCOUNTS_CONTACTS_CACHE_KEY],
      });
    },
    scope: {
      id: 'createUser',
    },
  });
}
